.loginContainer {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 55% 45%;
  grid-template-rows: 20% 50% 20%;
}
.loginMobileContainer {
  height: "100svh";
  width: 100vw;
  display: grid;
  /* grid-template-columns: 50% 50%; */
  grid-template-rows: 10% 20% 60% 20%;
}

.loginClipart {
  display: grid;
  align-items: center;
  grid-row-start: 2;
  margin-left: 20%;
  padding: 0%;
  /* align-self: center; */
}

.loginForm {
  display: grid;
  align-items: flex-end; /* Align items to the bottom */
  justify-items: end; /* Align items to the right */
  justify-content: flex-end;
  align-content: end;
  grid-column-start: 2;
  grid-row-start: 2;
  margin-right: 30%;
  font-family: "Poppins", sans-serif !important;
}

.loginMobileForm {
  display: grid;
  align-items: start; /* Align items to the bottom */
  justify-items: center; /* Align items to the right */
  justify-content: center;
  align-content: start;
  grid-row-start: 2;
  /* margin-right: 30%; */
  font-family: "Poppins", sans-serif !important;
}
.formMobileFields {
  display: grid;
  justify-items: end; /* Align items to the right */
  justify-content: end;
  align-content: end;
}
.formFields {
  display: grid;
  justify-items: end; /* Align items to the right */
  justify-content: flex-end;
  align-content: end;
}
