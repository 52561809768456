/* styles.css or index.css */

/* Universal selector to reset margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Ensures the document takes the full height and width */
html,
body {
  height: 100%; /* Full height */
  width: 100%; /* Full width */
  overflow: hidden; /* Prevent scrolling */
  background-color: "#F7F9FA";
}

/* Specifically ensuring body has no margin or padding */
body {
  margin: 0 !important; /* Override default margin */
  padding: 0; /* Remove any padding */
  display: block; /* Optional: Ensures body is a block element */
}

/* Ensure the root element takes the full size */
#root {
  height: 100%; /* Ensures #root takes full height */
  width: 100%; /* Ensures #root takes full width */
}
